@font-face{
    font-family: micro;
    src: url(../../Fuentes/Michroma-Regular.ttf);
}

@font-face{
    font-family: cormotant;
    src: url(../../Fuentes/CormorantGaramond-Bold.ttf);
}

.fuente_principal{
    font-family: micro;
    font-style: micro;
}

.fuente_secundaria{
    font-family: cormotant;
    font-style: cormotant;
    font-size: 1.2rem;
}


a{
    text-decoration: none;
    color: black

}
a:hover{
    color: black;
}