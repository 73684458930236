.form-control-foot {
  margin: 1rem 0;
}

.form-control-foot label,
.form-control-foot input,
.form-control-foot textarea {
  display: block;

}
.form-control-foot input{
border: 0;
background-color: transparent;
border-bottom: 2px solid black;
}

.form-control-foot input,
.form-control-foot textarea {
  width: 100%;
}

.form-control-foot input::placeholder{
  color: white;
}
