@font-face{
    font-family: micro;
    src: url(../../Fuentes/Michroma-Regular.ttf);
}

@font-face{
    font-family: cormotant;
    src: url(../../Fuentes/CormorantGaramond-Bold.ttf);
}

.icon{
    margin-top: 3rem;
    color: #df5453;
    text-align: center;
    font-size: 3rem;
}

.arriba{
    margin-top: 4rem;
}


.centrar{
    text-align: center;
}



:root {
	--mainColor: #df5453;
	--secondaryColor: #546193;
    --thirdColor: #c8946a;
    --fourthColor: #a2bbb0;
	--textColor: #eee;
}


.fuente_principal{
    font-family: micro;
    font-style: micro;
}

.fuente_secundaria_home{
    font-family: cormotant;
    font-style: cormotant;
    font-size: 2.0rem;
}

.fuente_secundaria_home2{
    font-family: cormotant;
    font-style: cormotant;
    font-size: 2.0rem;
}


.arriba-1rem{
    margin-top: 1rem;
}

.arriba-header{
    margin-top: 1.5rem;
}

.color_principal{
    color: #df5453
}

.color_secundario{
    color: #546193;
}

.primera{
    background-image: url("../../Img/fondo.webp");
    background-size: cover;
}

.primera h1{
    color: var(--mainColor);
    font-family: micro;
    font-style: micro;
    text-align: center;
    font-size: 5vw;
    margin-top: 3.0rem;
}

.primera h2{
    font-family: micro;
    font-style: micro;
    text-align: center;
    font-size: 2.0rem;
    margin-top: 3.0rem;
}


.segunda h1{
    color: var(--mainColor);
    text-align: center;
    font-style: micro;
    font-family: micro;
    font-size: 2.5rem;
}

.segunda h2{
    color: var(--secondaryColor);
    text-align: center;
    font-style: micro;
    font-size: 1.5rem;
    margin-top: 2rem;

}

.tercera h1{
    color: var(--mainColor);
    text-align: center;
    font-style: micro;
    font-family: micro;
    font-size: 2rem;
}


.tercera h2{
    margin-top: 2rem;
    text-align: left;
    font-style: micro;
    font-family: micro;
    font-size: 1rem;
    line-height : 40px;
    margin-bottom: 2rem;

}

.imagen-1{
    width: 15rem;
    height: 13rem;
    border-bottom: 3px solid var(--secondaryColor);
}

.cuarta-1 h1{
    color: #df5453;
    text-align: center;
    font-style: micro;
    font-family: micro;
    font-size: 2rem;
}

.cuarta h1{
    color: black;
    text-align: center;
    font-style: micro;
    font-family: micro;
    font-size: 2rem;
}

.quinta h1{
    margin-top: 2rem;
    color: var(--mainColor);
    text-align: center;
    font-style: micro;
    font-family: micro;
    font-size: 1.2rem;
    margin-bottom: 3rem;
}

.derecha{
    text-align: right;
    margin-right: 10vw;
}

.tamanio{
   margin-top: 1rem;
}

.tamanio h1{
    font-size: 1.2rem;
    color: var(--mainColor);
    text-align: center;
    font-family: micro;
    font-style: micro;
}

.izquierda{
    text-align: left;
}

.sexta h1{
    font-family: micro;
    font-style: micro;
    color: black;
}


.sexta h2{
    font-family: micro;
    font-style: micro;
    color: var(--mainColor);
}

.btn_1 {
    background-color: var(--secondaryColor);
    color: white;
    border-radius: 10px;
}

.btn_1:hover{
    background-color: var(--mainColor);
}


.btn_2 {
    background-color: var(--mainColor);
    color: white;
    border-radius: 10px;
}

.btn_1:hover{
    background-color: var(--secondaryColor);
}


.abajo{
    margin-top: 2rem;
}


.abajo-1{
    margin-top: 3rem;
}



.foot{
    background-color: #df5453;
    width: auto;
    height: auto;
    border: 1px black solid;
 }
 
 .arriba{
     margin-top: 3rem;
 }
 
 .arriba-logo{
     padding-top: 4rem;
     padding-bottom: 2rem;
 }
 
 .centrado{
     text-align: center;
 }
 
 
 .lista li{
    color: white;
   
}

.lista{
    color: white;
    list-style: none;
}

.lista a{
    text-decoration: none;
    color: white;
}
 
 .imagen-logo{
     border-bottom: 2px white solid;
     padding-bottom: 2rem
 }
 
 .lista{
     margin-top: 2rem;
 
 
 }
 

 
 .copy{
     background-color: white;
     width: 100%;
     height: 2vh;
 }
 
 .espacio{
     padding-top: 1rem;
 }
 