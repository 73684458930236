@font-face{
    font-family: micro;
    src: url(../../Fuentes/Michroma-Regular.ttf);
}

@font-face{
    font-family: cormotant;
    src: url(../../Fuentes/CormorantGaramond-Bold.ttf);
}



.titulo-footer{
    color: white;
    font-size: 2rem;
    font-family: micro;
    font-style: micro;
}


.fondo{
    background-color: transparent;
}

.arriba-1{
    margin-top: 1rem;
}